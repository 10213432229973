.MuiPickersModal-dialogRoot .MuiButton-label {
    color: #003260;
    font-weight: bolder;
}

body {
    margin: 0;
    background-color: #F2F1F6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: .88rem;
}

code {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.amplify-tabs {
    display: none !important;
}

[data-amplify-authenticator] {
    --amplify-components-button-primary-background-color: #003260
}

.imgFa {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.mobileBox {
    display: flex;
    align-items:center;
    align-content: space-between
}

.mobileDescriptions {
    flex-grow: 1;
    line-height: 0
}

.subRow {
    color: rgba(0,0,0,0.54);
    transform: scale(0.83);
    transform-origin: top left;
    line-height: 0.9;
}

.mobileTitle {
    line-height: 1.2;
    font-weight: 500;
    font-size: 1rem
}

@media screen and (max-width: 900px) {
    .amplify-input {
        outline: none !important;
    }
}