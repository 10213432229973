.root {
    border-radius: 5px;
    display: inline-block;
    padding: 0 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.88rem
}

.off {
    color: #D3000C;
    border: 1px solid #D3000C
}
.on {
    color: #019938;
    border: 1px solid #019938
}

.onRun {
    color: #C67943;
    border: 1px solid #C67943
}

.freeze {
    color: #939599;
    border: 1px solid #939599
}