.creditValue {
    vertical-align: middle;
    color: #C31916;
}

.amount {
    font-weight: 500;
    font-size: 1rem;
    text-align: right;
}

.off {
    color: #C31916
}

.space {
    margin-right: 0.3rem
}

.credit {
    text-align: right
}