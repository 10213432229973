.amount {
    font-weight: 600;
    font-size: 1rem
}

.off {
    color: #C67943
}

.space {
    margin-right: 0.3rem
}