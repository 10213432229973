.invoiceMobileBox {
    display: flex;
    align-items: center;
    align-content: space-between
}

.mobileDescriptions {
    flex-grow: 1;
    line-height: 0
}

.invoice {
    line-height: 1.2;
    font-weight: 500;
    font-size: 1rem;
}

.amount {
    font-weight: 500;
    font-size: 1rem;
    text-align: right
}

.max {
    font-weight: 500;
    font-size: 1rem;
    text-align: left
}

.off {
    color: #C67943
}

.subRow {
    color: rgba(0, 0, 0, 0.54);
    transform: scale(0.83);
    transform-origin: top left;
    line-height: 0.9
}

.space {
    margin-right: 0.3rem
}

.credit {
    text-align: right
}