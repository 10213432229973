.sm {
    margin-right: 30px
}

.front {
    font-family: ocr-a-std, monospace;
    text-shadow: 0 1px 1px black;
    top: 190px;
    margin-top: -84px;
    left: 22px;
    position: relative;
    z-index: 1199;
    font-weight: 500;
    font-style: normal;
    color: #CAD0D4
}

.hiddenFront {
    display: none
}
.frontPan {
    font-size: 18px
}
.frontUser {
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 11.6px
}
.frontPlateExpire {
    font-weight: 500;
    font-size: 11.6px;
    font-style: normal;
    color: #CAD0D4
}
.panToken {
    margin-right: 14px
}