.historyPaper {
    padding: 6px 16px;
    margin-bottom: 12px
}
.historyPaperMobile {
    padding: 4px 8px
}
.historyTable {
    border: 0;
    border-spacing: 0;
    width: auto
}

.historyTableLeft {
    margin-left: auto;
    margin-right: 0
}
.historyTableRight {
    margin-right: auto;
    margin-left: 0
}

.historyLabel {
    border-bottom: none;
    line-height: 1.1;
    font-weight: 400;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.64);
    padding: 6px 16px 6px 0
}
.historyValue {
    border-bottom: none;
    padding-right: 0;
    line-height: 1.1;
    font-weight: 600;
    word-break: break-word;
    overflow-wrap: break-word
}

.historyTableMobile {
    overflow-wrap: break-word
}