code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --amplify-primary-color: #003260;
    --swiper-theme-color: #003260;
}

:host(open){
    --border-radius: 1px
}

html{
    font-family: -apple-system, BlinkMacSystemFont,"Ubuntu", "Cantarell", "Oxygen", "Segoe UI","Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif !important;
}

[data-amplify-authenticator] {
    align-content: flex-start;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    padding-top: 8vh;
}

[data-amplify-authenticator] [data-amplify-router] {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}


[data-amplify-authenticator] [data-amplify-form] {
    padding: 1.2rem;
}